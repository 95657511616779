<template>
    <b-card class="treeCard" no-body>
        <b-card-body class="p-3">
        <h5 class="card-title mb-0">동별 관리</h5>
        </b-card-body>
        <ul id="demo" style="max-height: 250px; overflow-y: auto">
            <treeItem
                class="item"
                :selected="selected"
                :setDong="setDong"
                :item="treeData"
            ></treeItem>
        </ul>
    </b-card>    
</template>

<script>
import { dongList } from '../../api';
import treeItem from './ItemComponent'
export default {
    name:"TreeView",
    props: {
        setDong: Function,
        selected: String,
    },
    created() {
        this.getDongList();
    },
    components:{
        treeItem
    },
    data:() =>( {
        treeData: {
            name: "전체",
            children: [
                
            ]
        }
    }),
     methods: {
        async getDongList(){
            let dongListRes = await dongList();
            if(dongListRes.error == true){
                return;
            }
            for await(let dongListResItem of dongListRes.dongList){
                // dongListResItem.name = dongListResItem.name + " 동";
                dongListResItem.isChild = true;
            }
            this.treeData.children = dongListRes.dongList;
        }
    }
}
</script>

<style lang="scss">
.treeCard{
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
ul {
    margin:0px;
    padding:0 15px;
    list-style: none;
    li{
        padding: 7px;
    }
}
}
</style>