<template>
  <li>
    <div
        :class="{bold: item.name == selected}"
        @click="toggle">
        
          <i class="fas fa-folder text-warning" v-if="!isOpen"></i>
          <i class="fas fa-folder-open text-warning" v-else></i>
        {{ item.name }}
        
    </div>
    <ul v-show="isOpen" v-if="isFolder">
        <tree-item
        class="item"
        v-for="(child, index) in item.children"
        :selected="selected"
        :setDong="setDong"
        :key="index"
        :item="child"
        ></tree-item>
    </ul>
    </li>
</template>

<script>
export default {
    name:'treeItem',
    props: {
        item: Object,
        setDong: Function,
        selected: String
    },
    data: function () {
        return {
        isOpen: true
        };
    },
    computed: {
        isFolder: function () {
        return this.item.children && this.item.children.length;
        }
    },
    methods: {
        toggle: function () {
            this.setDong(this.item.name, this.item.isChild);
        // if (this.isFolder) {
        //     this.isOpen = !this.isOpen;
        // }
        },
        
    }
}
</script>

<style>

</style>