<template>
  <b-row>
    <b-col cols="3">
      <TreeView
        :setDong="setDong"
        :selected="dongSearch == '' ? '전체' : dongSearch"
      ></TreeView>
    </b-col>
    <b-col cols="9">
      <b-card no-body class="mb-4">
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">
            세대인증 요청 목록 (신규 요청 {{ pendingCount }}건)
          </h5>
        </b-card-body>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :fields="fields"
        >
          <template #cell(idx)="data">
            <b-button
              v-if="data.item.status != null"
              variant="outline-danger"
              @click="unitPendingStatusChange(data.item.idx, 3)"
            >
              삭제 </b-button
            >&nbsp;
            <b-button
              variant="outline-success"
              v-if="data.item.status == 0 || data.item.status == 2"
              @click="unitPendingStatusChange(data.item.idx, 1)"
            >
              승인 </b-button
            >&nbsp;
            <b-button
              variant="outline-danger"
              v-if="data.item.status == 0"
              @click="unitPendingStatusChange(data.item.idx, 2)"
            >
              거부
            </b-button>
          </template>
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == 1"
              pill
              :class="`px-2 text-white badge bg-success`"
            >
              인중
            </b-badge>
            <b-badge
              v-if="data.item.status == 2"
              pill
              :class="`px-2 text-white badge bg-danger`"
            >
              미인증
            </b-badge>
            <b-badge
              v-if="data.item.status == 0"
              pill
              :class="`px-2 text-white badge bg-secondary`"
            >
              요청
            </b-badge>
          </template>
          <template #cell(isInfoSame)="data">
            <b-badge
              v-if="data.item.isInfoSame && data.item.status != null"
              pill
              :class="`px-2 text-white badge bg-success`"
            >
              일치
            </b-badge>
            <b-badge
              v-if="!data.item.isInfoSame && data.item.status != null"
              pill
              :class="`px-2 text-white badge bg-danger`"
            >
              불일치
            </b-badge>
          </template>
        </b-table>
      </b-card>

      <b-row>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          class="my-0"
          @change="changePage"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>

<style>
.b-table {
  border: 1px solid #eee;
}
</style>

<script>
import TreeView from "../components/apps/TreeView";
import { unitPendingList, unitPendingStatus, pswdVerifySwal } from "../api.js";
export default {
  name: "PaginationTable",

  data: () => ({
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "dong",
        label: "동",
      },
      {
        key: "ho",
        label: "호",
      },
      {
        key: "unitInfo",
        label: "세대 정보",
      },
      {
        key: "pendingInfo",
        label: "가입 정보",
      },
      {
        key: "status",
        label: "상태",
      },
      {
        key: "isInfoSame",
        label: "정보일치",
      },
      {
        key: "idx",
        label: "작업",
      },
    ],
    items: [],
    search: "",
    dongSearch: "",
    totalRows: 0,
    pendingCount: 0,
    currentPage: 1,
    perPage: 10,
  }),
  components: { TreeView },
  methods: {
    setDong(dong, isChild) {
      if (isChild) {
        this.dongSearch = dong;
        this.currentPage = 1;
        this.search = "";
        this.getUnitPendingList();
      } else {
        this.dongSearch = "";
        this.currentPage = 1;
        this.search = "";
        this.getUnitPendingList();
      }
    },
    async unitPendingStatusChange(idx, type) {
      if (type == 1) {
        this.$swal
          .fire({
            icon: "info",
            title: "세대 인증 요청을 승인하시겠습니까?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "승인",
            confirmButtonColor: "#0bb7af",
            cancelButtonText: "취소",
          })
          .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let unitStatusChangeRes = await unitPendingStatus(idx, type);
              if (unitStatusChangeRes.error) {
                this.$swal.fire("오류", unitStatusChangeRes.message, "error");
                if(unitStatusChangeRes.loginRequired){
                  this.$router.replace("/login");
                }
              } else {
                this.$swal.fire(
                  "성공",
                  "세대 인증 요청을 승인했습니다.",
                  "success"
                );
                this.currentPage = 1;
                this.search = "";
                await this.getUnitPendingList();
              }
            }
          });
      }
      if (type == 2) {
        this.$swal
          .fire({
            icon: "info",
            title: "세대 인증 요청을 거부하시겠습니까?",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "거절",
            confirmButtonColor: "#f64e60",
            cancelButtonText: "취소",
          })
          .then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              let unitStatusChangeRes = await unitPendingStatus(idx, type);
              if (unitStatusChangeRes.error) {
                this.$swal.fire("오류", unitStatusChangeRes.message, "error");
                if(unitStatusChangeRes.loginRequired){
                  this.$router.replace("/login");
                }
              } else {
                this.$swal.fire(
                  "성공",
                  "세대 인증 요청을 거부했습니다.",
                  "success"
                );
                this.currentPage = 1;
                this.search = "";
                await this.getUnitPendingList();
              }
            }
          });
      }
      if (type == 3) {
        pswdVerifySwal(this.$swal, () => {
          this.$swal
            .fire({
              icon: "info",
              title: "세대 인증 요청을 삭제하시겠습니까?",
              html:
                "세대 인증 요청을 삭제하실 경우,<br/>해당 요청 건의 승인이 <b>취소</b>됩니다.",
              showDenyButton: false,
              showCancelButton: true,
              confirmButtonText: "삭제",
              confirmButtonColor: "#f64e60",
              cancelButtonText: "취소",
            })
            .then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                let unitStatusChangeRes = await unitPendingStatus(idx, type);
                if (unitStatusChangeRes.error) {
                  this.$swal.fire("오류", unitStatusChangeRes.message, "error");
                  if(unitStatusChangeRes.loginRequired){
                    this.$router.replace("/login");
                  }
                } else {
                  this.$swal.fire(
                    "성공",
                    "세대 인증 요청을 삭제했습니다.",
                    "success"
                  );
                  this.currentPage = 1;
                  this.search = "";
                  await this.getUnitPendingList();
                }
              }
            });
        });
      }
    },
    changePage(page) {
      this.currentPage = page;
      this.getUnitPendingList();
    },
    searchChange() {
      this.currentPage = 1;
      this.getUnitPendingList();
    },
    searchClear() {
      this.currentPage = 1;
      this.search = "";
      this.getUnitPendingList();
    },
    async getUnitPendingList() {
      let unitListRes = await unitPendingList(
        this.currentPage,
        this.dongSearch
      );
      if (unitListRes.error == true) {
        this.$bvToast.toast(unitListRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true,
        });
        if(unitListRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }

      this.items = unitListRes.pendingUnits;
      this.totalRows = unitListRes.count;
      this.pendingCount = unitListRes.pendingCount;
    },
  },
  mounted() {
    this.getUnitPendingList();
  },
};
</script>
